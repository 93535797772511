"use client";

import { useRef } from "react";
import Image from "next/image";
import Swal from "sweetalert2";
import { setCookie } from "nookies";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "@/components/Input";
import Checkbox from "@/components/Checkbox";
import { setAuthHeader } from "@/services/api";
import { UserService } from "@/services/api/User";
import styles from "./styles.module.css";
import { ILoginFormValues } from "./types";
import Link from "next/link";

const LoginFormSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  remember_me: Yup.boolean(),
});

export default function LoginForm() {
  const formRef = useRef<FormikProps<ILoginFormValues>>(null);

  const handleFormSubmit = async (values: ILoginFormValues) => {
    try {
      const loginRes = (await UserService.Login(values)).data;
      setAuthHeader(loginRes);
      setCookie(null, "auth", btoa(JSON.stringify(loginRes)), {
        maxAge: values.remember_me ? 30 * 24 * 60 * 60 : undefined,
      });
      window.location.href = "/";
    } catch (err: any) {
      formRef.current?.setErrors(err?.response?.data ?? {});
      Swal.fire({
        title: "Error",
        text:
          err?.response?.data?.detail ??
          err?.response?.data?.non_field_errors?.join(", ") ??
          err?.message ??
          "An error occurred while logging in. Please, try again later.",
        icon: "error",
      });
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ username: "", password: "", remember_me: true }}
      validationSchema={LoginFormSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className={styles["auth-form"]} onSubmit={handleSubmit}>
          <Image
            style={{ width: "264px", height: "auto" }}
            className={styles["logo"]}
            alt="Logo"
            src="/assets/images/logo.webp"
            width={0}
            height={0}
            sizes="100vw"
          />

          <span className={styles["form-inputs"]}>
            <Input
              required
              name="username"
              placeholder="Username"
              value={values.username}
              disabled={isSubmitting}
              onChange={handleChange}
              error={touched.username ? errors.username : undefined}
            />

            <Input
              required
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              disabled={isSubmitting}
              onChange={handleChange}
              error={touched.password ? errors.password : undefined}
            />
          </span>

          <Checkbox
            label="Remember me"
            name="remember_me"
            checked={values.remember_me}
            onChange={() => {
              setFieldValue("remember_me", !values.remember_me);
            }}
          />

          <button type="submit" className="btn-lg" disabled={isSubmitting}>
            Login
          </button>

          <Link className={styles["disclaimer-link"]} href="/disclaimer">
            Disclaimer
          </Link>
        </form>
      )}
    </Formik>
  );
}
