import { type ICredentials, api } from ".";
import type { IUser } from "@/models";
import type { ILoginFormValues } from "@/components/Form/Login/types";

export const UserService = {
  Login: (values: ILoginFormValues) => {
    // @ts-ignore local field only
    delete values.remember_me;

    return api.post<ICredentials>("token/", values);
  },

  GetCurrent: () => {
    return api.get<IUser>("int/current_user");
  },
};
